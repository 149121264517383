import React from "react"
import { Helmet } from "react-helmet"
import "../../static/assets/scss/pages/page.scss"
import LayoutDark from "./shared/layout/layout-dark"
import SignUpFormComponent from "./shared/components/sign-up-form.component"

const routes = require("../types/routes")

export default class TermsOfUse extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: Terms of Service</title>
          <meta name="title" content="Contingent:  Terms of Service" />
          <meta
            name="description"
            content="The intelligence platform for procurement, compliance and
                    operational resilience leaders"
          />
          <meta
            name="og:description"
            content="The intelligence platform for procurement, compliance and
                    operational resilience leaders"
          />
          <meta property="og:type" content="website" />
          <link
            rel="canonical"
            href={`${process.env.BASE_URL}/terms-of-service/`}
          />
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/terms-of-service/`}
          />
          <meta property="og:title" content="Contingent:  Terms of Service" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`${process.env.BASE_URL}/terms-of-service/`}
          />
          <meta
            property="twitter:title"
            content="Contingent: Terms of Service"
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic"}>
            <div className={"container contact-us"}>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"content document-sidebar"}>
                    <h1 className={"title"}>Terms and Services</h1>
                    <p className={"description"}>
                      Last updated on 11 December, 2020
                    </p>
                  </div>
                  <div className={"document"}>
                    <h3>1. Terms</h3>
                    <p>
                      By accessing the website at{" "}
                      <a href="http://contingent.ai">http://contingent.ai</a>,
                      you are agreeing to be bound by these terms of service,
                      all applicable laws and regulations, and agree that you
                      are responsible for compliance with any applicable local
                      laws. If you do not agree with any of these terms, you are
                      prohibited from using or accessing this site. The
                      materials contained in this website are protected by
                      applicable copyright and trademark law.
                    </p>
                    <h3>2. Use Licence</h3>
                    <ol type="a">
                      <li>
                        Permission is granted to temporarily download one copy
                        of the materials (information or software) on
                        Contingent's website for personal, non-commercial
                        transitory viewing only. This is the grant of a licence,
                        not a transfer of title, and under this licence you may
                        not:
                      </li>
                      <ol type="i">
                        <li>modify or copy the materials;</li>
                        <li>
                          use the materials for any commercial purpose, or for
                          any public display (commercial or non-commercial);
                        </li>
                        <li>
                          attempt to decompile or reverse engineer any software
                          contained on Contingent's website;
                        </li>
                        <li>
                          remove any copyright or other proprietary notations
                          from the materials; or
                        </li>
                        <li>
                          transfer the materials to another person or "mirror"
                          the materials on any other server.
                        </li>
                      </ol>
                      <li>
                        This licence shall automatically terminate if you
                        violate any of these restrictions and may be terminated
                        by Contingent & Future Technologies Ltd (12035038) at
                        any time. Upon terminating your viewing of these
                        materials or upon the termination of this licence, you
                        must destroy any downloaded materials in your possession
                        whether in electronic or printed format.
                      </li>
                    </ol>
                    <h3>3. Disclaimer</h3>
                    <ol type="a">
                      <li>
                        The materials on Contingent's website are provided on an
                        'as is' basis. Contingent & Future Technologies Ltd
                        (12035038) makes no warranties, expressed or implied,
                        and hereby disclaims and negates all other warranties
                        including, without limitation, implied warranties or
                        conditions of merchantability, fitness for a particular
                        purpose, or non-infringement of intellectual property or
                        other violation of rights.
                      </li>
                      <li>
                        Further, Contingent & Future Technologies Ltd (12035038)
                        does not warrant or make any representations concerning
                        the accuracy, likely results, or reliability of the use
                        of the materials on its website or otherwise relating to
                        such materials or on any sites linked to this site.
                      </li>
                    </ol>
                    <h3>4. Limitations</h3>
                    <p>
                      In no event shall Contingent & Future Technologies Ltd
                      (12035038) or its suppliers be liable for any damages
                      (including, without limitation, damages for loss of data
                      or profit, or due to business interruption) arising out of
                      the use or inability to use the materials on Contingent's
                      website, even if Contingent & Future Technologies Ltd
                      (12035038) or a Contingent & Future Technologies Ltd
                      (12035038) authorised representative has been notified
                      orally or in writing of the possibility of such damage.
                      Because some jurisdictions do not allow limitations on
                      implied warranties, or limitations of liability for
                      consequential or incidental damages, these limitations may
                      not apply to you.
                    </p>
                    <h3>5. Accuracy of materials</h3>
                    <p>
                      The materials appearing on Contingent's website could
                      include technical, typographical, or photographic errors.
                      Contingent & Future Technologies Ltd (12035038) does not
                      warrant that any of the materials on its website are
                      accurate, complete or current. Contingent & Future
                      Technologies Ltd (12035038) may make changes to the
                      materials contained on its website at any time without
                      notice. However Contingent & Future Technologies Ltd does
                      not make any commitment to update the materials.
                    </p>
                    <h3>6. Links</h3>
                    <p>
                      Contingent & Future Technologies Ltd (12035038) has not
                      reviewed all of the sites linked to its website and is not
                      responsible for the contents of any such linked site. The
                      inclusion of any link does not imply endorsement by
                      Contingent & Future Technologies Ltd of the site. Use of
                      any such linked website is at the user's own risk.
                    </p>
                    <h3>7. Modifications</h3>
                    <p>
                      Contingent & Future Technologies Ltd (12035038) may revise
                      these terms of service for its website at any time without
                      notice. By using this website you are agreeing to be bound
                      by the then current version of these terms of service.
                    </p>
                    <h3>8. Governing Law</h3>
                    <p>
                      These terms and conditions are governed by and construed
                      in accordance with the laws of United Kingdom and you
                      irrevocably submit to the exclusive jurisdiction of the
                      courts in that State or location.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
